import React from 'react';
import { Row, Col, Button, Modal, ModalBody, ModalFooter } from 'react-bootstrap';
import { useRef, useEffect } from 'react';
import InputMask from 'react-input-mask';
import { validarCPF } from '../../utils/cpf';
import { validarCNPJ } from '../../utils/cnpj';
import { useToasts } from 'react-toast-notifications';
import ConsultaCNPJ from '../../views/databit/cnpj';

const CreateInputMask = (props) => {
  const { valuesfield, setvaluesfield } = props;
  const { valuesfield2, setvaluesfield2 } = props;
  const [mascara, setMascara] = React.useState();
  const inputRef = useRef(null);
  const { addToast } = useToasts();
  const [largura, setLargura] = React.useState(props.measure);
  const [showcons, setShowcons] = React.useState(false);
  const [cnpj, setCnpj] = React.useState('');

  useEffect(() => {
    switch (props.typemask) {
      case 1: {
        // CPF
        setMascara('999.999.999-99');
        setLargura(props.size - 1 + 'rem');
        break;
      }
      case 2: {
        // CNPJ
        setMascara('99.999.999/9999-99');
        setLargura(props.size - 1 + 'rem');
        break;
      }
      case 3: {
        // CEP
        setMascara('99.999-999');
        break;
      }
      case 4: {
        // Telefone fixo
        setMascara('(99) 9999-9999');
        break;
      }
      case 5: {
        // Celular e WhatsAPP
        setMascara('(99) 99999-9999');
        break;
      }
      case 6: {
        // Plano de Contas
        setMascara('9.9.99.99.99.9999');
        break;
      }
      case 7: {
        // Hora
        setMascara('99:99');
        break;
      }
      case 8: {
        // Outro
        setMascara(props.mask);
        break;
      }
    }
    document.getElementById(props.name).mask = mascara;
  }, []);

  const handleChangefield = (e, index) => {
    if (props.typemask < 7) {
      valuesfield[index] = justNumbers(e.target.value);
      const valor = valuesfield[index];
      switch (props.typemask) {
        case 1: {
          // CPF
          if (valor.length === 11) {
            if (!validarCPF(valor)) {
              valuesfield[index] = '';
              addToast('CPF ' + valor + ' Inválido', {
                placement: 'bottom-rigth',
                appearance: 'error',
                autoDismiss: true
              });
            }
          }
          break;
        }
        case 2: {
          // CNPJ
          setCnpj(valor);
          if (valor.length === 14) {
            if (!validarCNPJ(valor)) {
              valuesfield[index] = '';
              addToast('CNPJ ' + valor + '  Inválido', {
                placement: 'bottom-rigth',
                appearance: 'error',
                autoDismiss: true
              });
            }
          }
          break;
        }
      }
    } else {
      valuesfield[index] = e.target.value;
    }
    setvaluesfield([...valuesfield]);
  };

  const handleChangefield2 = (e, index) => {
    if (props.typemask < 7) {
      valuesfield2[index] = justNumbers(e.target.value);
      const valor = valuesfield2[index];
      switch (props.typemask) {
        case 1: {
          // CPF
          if (valor.length === 11) {
            if (!validarCPF(valor)) {
              valuesfield2[index] = '';
              addToast('CPF ' + valor + ' Inválido', {
                placement: 'bottom-rigth',
                appearance: 'error',
                autoDismiss: true
              });
            }
          }
          break;
        }
        case 2: {
          // CNPJ
          if (valor.length === 14) {
            if (!validarCNPJ(valor)) {
              valuesfield2[index] = '';
              addToast('CNPJ ' + valor + '  Inválido', {
                placement: 'bottom-rigth',
                appearance: 'error',
                autoDismiss: true
              });
            }
          }

          break;
        }
      }
    } else {
      valuesfield2[index] = e.target.value;
    }
    setvaluesfield2([...valuesfield2]);
  };

  function justNumbers(text) {
    if (text !== '' && text !== undefined) {
      var numbers = text.replace(/[^0-9]/g, '');
      return numbers;
    } else {
      return '';
    }
  }

  const handleKeyDown = (event, index) => {
    const key = event.keyCode;
    const campo = event.target.name;
    let campoFoco = '';
    switch (key) {
      case 40:
      case 13: {
        if (index < props.fields.length - 1) {
          if (props.interval) {
            if (campo.includes('_2')) {
              campoFoco = props.fields[props.index + 1].campo;
            } else {
              campoFoco = props.name + '_2';
            }
          } else {
            campoFoco = props.fields[props.index + 1].campo;
          }
          try {
            document.getElementById(campoFoco).focus();
          } catch (error) {
            //console.log(error);
          }
        }
        break;
      }
      case 38: {
        if (index > 0 || props.interval) {
          if (props.interval) {
            if (campo.includes('_2')) {
              campoFoco = props.name;
            } else {
              campoFoco = props.fields[props.index - 1].campo;
            }
          } else {
            campoFoco = props.fields[props.index - 1].campo;
          }
          try {
            document.getElementById(campoFoco).focus();
          } catch (error) {
            //console.log(error);
          }
        }
        break;
      }
    }
  };

  const handleClosecons = () => {
    setShowcons(false);
  };

  return (
    <React.Fragment>
      <div style={{ width: largura, padding: '5px 5px 5px 5px' }}>
        <Row>
          <Col>
            <p className="mb-1 text-muted" style={{ textAlign: 'left' }}>
              {props.title} :
            </p>
            <Col>
              <InputMask
                id={props.name}
                name={props.name}
                className="form-control"
                value={valuesfield[props.index]}
                onChange={(e) => handleChangefield(e, props.index)}
                onBlur={props.methodBlur}
                mask={mascara}
                ref={inputRef}
                onKeyDown={props.onkeydown !== undefined ? props.onkeydown : (event) => handleKeyDown(event, props.index)}
                disabled={props.disabled}
                readOnly={props.readonly}
                style={!props.required ? { backgroundColor: '#f4f7fa' } : { backgroundColor: '#e2ecfa' }}
                autoComplete="off"
              ></InputMask>
            </Col>
          </Col>
          {props.typemask === 2 && !props.interval ? (
            <Button
              id="btnSearch"
              style={{ marginTop: '22px', marginLeft: '-9px' }}
              className="btn-icon"
              disabled={props.disabled}
              onClick={(e) => setShowcons(true)}
            >
              <i className={'feather icon-search'} />
            </Button>
          ) : (
            <></>
          )}
          {props.interval ? (
            <Col>
              <div style={{ marginLeft: '-14px' }}>
                <p className="mb-1 text-muted" style={{ textAlign: 'left' }}>
                  até :
                </p>
                <InputMask
                  id={props.name + '_2'}
                  name={props.name + '_2'}
                  className="form-control"
                  value={valuesfield2[props.index]}
                  onChange={(e) => handleChangefield2(e, props.index)}
                  onBlur={props.methodBlur}
                  mask={mascara}
                  onKeyDown={props.onkeydown !== undefined ? props.onkeydown : (event) => handleKeyDown(event, props.index)}
                  disabled={props.disabled}
                  readOnly={props.readonly}
                  autoComplete="off"
                ></InputMask>
              </div>
            </Col>
          ) : (
            <></>
          )}
        </Row>
      </div>
      <Modal size="xl" show={showcons} centered={true} onHide={handleClosecons}>
        <Modal.Header className="h5" closeButton>
          <i className={'feather icon-search h1'} />
          &nbsp;Consulta de CNPJ : {valuesfield[props.index]}
        </Modal.Header>
        <ModalBody>
          <ConsultaCNPJ cnpjselec={valuesfield[props.index]} setCnpjselec={(data) => setCnpj(data)}></ConsultaCNPJ>
        </ModalBody>
        <ModalFooter>
          <Button id="btnSair" className="btn btn-success shadow-2 mb-2" onClick={(e) => setShowcons(false)}>
            <i className={'feather icon-x'} /> Sair
          </Button>
        </ModalFooter>
      </Modal>
    </React.Fragment>
  );
};

export default CreateInputMask;

import connectInstance from '../connectinstance';

export const getConnectionCNPJ = async (cnpj) => {
  try {
    return await connectInstance.get('getConnectionCNPJ/' + cnpj);
  } catch (error) {
    //console.log(error);
  }
};

export const getConnectionToken = async (token) => {
  try {
    return await connectInstance.get('getConnectionToken/' + token);
  } catch (error) {
    //console.log(error);
  }
};

export const getClass = async (object) => {
  try {
    return await connectInstance.get('getClass/' + object);
  } catch (error) {
    //console.log(error);
  }
};

export const getListClass = async (object) => {
  try {
    return await connectInstance.get('getListClass/' + object);
  } catch (error) {
    //console.log(error);
  }
};

export const getListClassall = async (object) => {
  try {
    return await connectInstance.get('getListClassall/' + object);
  } catch (error) {
    //console.log(error);
  }
};

export const onMenu = async (system, modules, filter, admin, permission) => {
  try {
    return await connectInstance.post('onMenu/', {
      system: system,
      modules: modules,
      filter: filter,
      admin: admin,
      permissao: permission
    });
  } catch (error) {
    console.log(error);
  }
};

export const getAdmin = async (user) => {
  try {
    return await connectInstance.get('getAdmin/' + user);
  } catch (error) {
    //console.log(error);
  }
};

export const getPasswordAdmin = async (user, password) => {
  try {
    return await connectInstance.get('getPasswordAdmin/' + user + '/' + password);
  } catch (error) {
    //console.log(error);
  }
};

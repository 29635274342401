import { DATABIT } from '../../config/constant';
import axios from 'axios';

const connectInstance = axios.create({
  baseURL: DATABIT.ulrconnect,
  timeout: 30000,
  headers: {
    'Access-Control-Allow-Headers': '*',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': '*'
  }
});

export default connectInstance;

import React from 'react';
import CreateInput from '../CreateInput';
import CreateMult from '../CreateMult';
import CreateFind from '../CreateFind';
import CreateInputMask from '../CreateInputMask';
import CreateDate from '../CreateDate';
import CreateText from '../CreateText';
import CreateNumber from '../CreateNumber';
import CreateCheck from '../CreateCheck';
import CreateRadio from '../CreateRadio';
import CreateSelect from '../CreateSelect';

export const CreateObject = (props) => {
  const { valuesfield, setvaluesfield } = props;
  const { valuesfield2, setvaluesfield2 } = props;
  const [listoptions, setListoptions] = React.useState([]);

  switch (props.field.tipoobject) {
    case 1: {
      // Input
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateInput
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              interval={props.field.tipofiltro === 3}
              measure={props.field.measure}
              fields={props.fields}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              disabled={props.disabled}
              readonly={props.field.readonly}
              charnormal={props.field.charnormal}
              onkeydown={props.onkeydown}
              required={props.required}
              ispassword={false}
            ></CreateInput>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 2: {
      // Pesquisa
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateFind
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              table={props.field.tabelaref}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              measure={props.field.measure}
              widthname={props.field.widthname}
              fields={props.fields}
              disabled={props.disabled}
              readonly={props.field.readonly}
              ref={props.field.ref}
              filteraux={props.field.filteraux}
              onkeydown={props.onkeydown}
            ></CreateFind>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 3: {
      // Multi-seleção
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateMult
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              table={props.field.tabelaref}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              measure={props.field.measure}
              widthname={props.field.widthname}
              fields={props.fields}
              disabled={props.disabled}
              readonly={props.field.readonly}
            ></CreateMult>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 4: {
      // Númerico
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateNumber
              index={props.index}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              interval={props.field.tipofiltro === 3}
              measure={props.field.measure}
              decimal={props.field.decimal}
              fields={props.fields}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
            ></CreateNumber>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 5: {
      return (
        // Data
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateDate
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              interval={props.field.tipofiltro === 3}
              measure={props.field.measure}
              fields={props.fields}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
            ></CreateDate>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 6: {
      // Texto
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateText
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              fields={props.fields}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
              lines={props.field.lines}
            ></CreateText>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 8: {
      // Input com máscaras
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateInputMask
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              interval={props.field.tipofiltro === 3}
              measure={props.field.measure}
              typemask={props.field.tipomascara}
              mask={props.field.mascara}
              fields={props.fields}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
            ></CreateInputMask>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 9: {
      // CreckBox
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateCheck
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              fields={props.fields}
              valuechecked={props.field.valuechecked}
              valueunchecked={props.field.valueunchecked}
              measure={props.field.measure}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
            ></CreateCheck>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 10: {
      // RadioGroup
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateRadio
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              fields={props.fields}
              itens={props.field.itens}
              values={props.field.values}
              measure={props.field.measure}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
            ></CreateRadio>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }

    case 11: {
      // Select Normal
      let optionsfim;
      let arrayitens = [];
      let arrayvalues = [];
      if (props.field.itens !== '' && props.field.itens !== undefined) {
        arrayitens = props.field.itens.split(',');
        arrayvalues = props.field.values.split(',');

        let option = [];
        arrayitens.forEach((item, index) => {
          const option2 = { value: arrayvalues[index], label: arrayitens[index] };
          option = option.concat(option2);
        });
        optionsfim = option;
      } else {
        optionsfim = props.field.options;
      }
      if (optionsfim === undefined) {
        optionsfim = [{ value: 'null', label: 'Sem itens para serem selecionados!' }];
      }
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateSelect
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              fields={props.fields}
              options={optionsfim}
              measure={props.field.measure}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
              valuesoption={arrayvalues}
              firstdefault={false}
            ></CreateSelect>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 12: {
      // Select Tabela
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateSelect
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              fields={props.fields}
              options={listoptions}
              measure={props.field.measure}
              disabled={props.disabled}
              readonly={props.field.readonly}
              onkeydown={props.onkeydown}
              required={props.required}
              tabelaref={props.field.tabelaref}
              fieldlist={props.field.campolist}
              fieldvalue={props.field.camporefdrop}
              filteraux={props.field.filteraux}
              firstdefault={props.field.filteraux}
            ></CreateSelect>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
    case 13: {
      // Password
      return (
        <React.Fragment>
          {props.invisible === false || props.invisible === undefined ? (
            <CreateInput
              index={props.index}
              size={props.field.tamanho}
              title={props.field.funcao}
              name={props.field.campo}
              interval={props.field.tipofiltro === 3}
              measure={props.field.measure}
              fields={props.fields}
              valuesfield={valuesfield}
              setvaluesfield={(data) => setvaluesfield(data)}
              valuesfield2={valuesfield2}
              setvaluesfield2={(data) => setvaluesfield2(data)}
              disabled={props.disabled}
              readonly={props.field.readonly}
              charnormal={props.field.charnormal}
              onkeydown={props.onkeydown}
              required={props.required}
              ispassword={true}
              viewpass={true}
            ></CreateInput>
          ) : (
            <></>
          )}
        </React.Fragment>
      );
    }
  }
};

import React, { useEffect } from 'react';
import { BrowserRouter } from 'react-router-dom';

// auth provider
import { JWTProvider as AuthProvider } from './contexts/JWTContext';

import routes, { renderRoutes } from './routes';
import { LicenseInfo } from '@mui/x-license';
import { DATABIT } from './config/constant';

LicenseInfo.setLicenseKey(DATABIT.licensedatagrid);

const App = () => {
  useEffect(() => {
    const handleBeforeUnload = (event) => {
      alert('Você está saindo da página!');
      event.preventDefault();
      event.returnValue = '';
      localStorage.setItem('saiu', 'true');
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  return (
    <React.Fragment>
      <BrowserRouter basename={process.env.REACT_APP_BASE_NAME}>
        <AuthProvider>{renderRoutes(routes)}</AuthProvider>
      </BrowserRouter>
    </React.Fragment>
  );
};

export default App;
